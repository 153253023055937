.landingDownload {
    background-image: url("../assets/landingZip.png") !important;
    background-size: cover !important;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-position: top;
}
  
.gb-card-desktop-landing {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
    border-radius: 4px;
    width: calc(100% - 40px);
    .ant-card-body {
        padding: 32px !important;
        width: 300px;
        position: relative;
        left: 50px;
    }
}

.button-secundary-gibobs {
    border-color: #02c3cd;
    border-radius: 4px;
    color: #02c3cd;
    width: auto;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
  }
  
  .button-primari-gibobs {
    background-color: #02c3cd;
    border-radius: 4px;
    color: #ffff;
    width: auto;
    height: 32px;
    font-size: 14px;
    text-shadow: none;
    box-shadow: none;
    padding: 0 10px;
  }